import _ from 'lodash';

export const cleanObject = (obj, cleaner) =>
  _.omitBy(
    obj,
    val =>
      _.isNil(val) ||
      (_.isArray(val) && val.length === 0) ||
      (_.isFunction(cleaner) && cleaner(val)),
  );

/**
 * convert query object of router to a query object tree
 * @param {*} query query object from router
 */
export const queryToObject = query => {
  const rtn = {};
  _.forIn(query, (value, key) => {
    _.set(rtn, key, value);
  });
  return rtn;
};

const visit = (obj, paths, acc) => {
  _.forIn(obj, (value, key) => {
    if (_.isPlainObject(value)) {
      visit(value, [...paths, key], acc);
    } else if (
      _.isString(value) ||
      _.isNumber(value) ||
      _.isArray(value) ||
      _.isNil(value)
    ) {
      acc[[...paths, key].join('.')] = value;
    }
  });
};

/**
 * convert query object tree back to router query object
 * @param {*} obj the query object tree
 */
export const objectToQuery = obj => {
  const query = {};
  visit(obj, [], query);
  return query;
};

export const pluralText = (count, [singleTxt, pluralTxt]) => {
  return count === 1 ? singleTxt : pluralTxt;
};

export const getOneOf = (obj, paths, def) => {
  const foundPath = _.find(_.castArray(paths || []), (path) => _.has(obj, path));
  return foundPath ? _.get(obj, foundPath) : def;
};

export const getNewsSlug = news => {
  const title = _.get(news, 'title');
  const date_scheduled = _.get(news, 'date_scheduled');
  return `/news/${_.kebabCase(title)}-${date_scheduled}/`;
};

export default {
  cleanObject,
  queryToObject,
  objectToQuery,
  pluralText,
};
