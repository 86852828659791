import React from 'react';

export default (text) => {
  const paras = `${text}`.split(/(\\n)/);
  const delim = "\\n";
  return (
    <>
    {
      paras.map((para, index) => {
        if(para === delim) return null;
        return <div key={index} >{para}<br /></div>
      })
    }
    </>
  )
}
